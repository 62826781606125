.quiz {
  height: 100vh;
  width: 80vw;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: calc(7vh + 3vw) 0;

  .quizQuestionContainer {
    width: 100%;
    h1 {
      color: #bff2f8;
      font-size: calc(1.6vh + 2vw);
    }
  }

  .quizOptionContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    column-gap: 5vw;

    h1 {
      border: calc(0.2vh + 0.2vw) solid #bff2f8;
      border-radius: calc(0.8vh + 0.8vw);
      padding: calc(0.5vh + 0.5vw) calc(0.5vh + 1.5vw);
      margin: 1rem;
      color: #bff2f8;
      cursor: pointer;
      font-size: calc(1.5vh + 1.5vw);
      width: calc(5vh + 25vw);

      &:hover {
        background-color: #bff2f8;
        color: #203864;
      }
    }

    .active {
      background-color: #bff2f8;
      color: #203864;
    }
  }

  .quizAnswerScreen {
    display: flex;
    flex-direction: column;
    height: 80vh;
    justify-content: space-between;
    align-items: center;

    .gameFinishMessage {
      margin: 1rem;
      font-size: calc(1.5vh + 1.5vw);
      color: #bff2f8;
    }

    .answerImages {
      height: calc(15vh + 5vw);
    }

    .gameFinishTitle {
      font-size: calc(1.5vh + 1.5vw);
      color: #bff2f8;
    }
  }

  .submitAns {
    padding: 0.5rem 1.2rem;
    border-radius: 0.2rem;
    background-color: rgb(86, 206, 196);
    color: rgb(3, 55, 107);
    outline: none;
    border: none;
    cursor: pointer;
    font-size: 1.5rem;
    font-weight: 700;
    transition: transform 0.2s ease;
    margin: 0.5rem auto;

    &:hover {
      background-color: rgb(129, 218, 188);
    }
    &:active {
      transform: translateY(2px);
    }
  }
}

.toRoom2Link {
  cursor: pointer;
  &:hover {
    text-shadow: 2px 2px rgb(0, 81, 255);
  }
}

.takeHelp {
  height: 100vh;
  width: 100vw;
  display: flex;

  position: relative;

  .options {
    position: absolute;

    left: 26vw;
    bottom: calc(13vh + 13vw);

    width: 50vw;
    display: flex;
    justify-content: space-around;
    button {
      text-align: center;
      font-size: calc(1vh + 1.5vw);
      font-weight: 800;
      border-radius: calc(0.8vh + 0.8vw);
      border: none;
      width: calc(10.5vh + 10.5vw);
      padding: calc(0.4vh + 0.4vw) calc(0.6vh + 0.6vw);
      margin: calc(0.4vh + 0.4vw);
      background-color: #1aa1e6;
      color: #fff;
      cursor: pointer;
      &:hover {
        background-color: #bff2f8;
        color: #1aa1e6;
      }
    }
  }
}

.confirmAns__overlay {
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.527);
  position: fixed;
  top: 0;
  left: 0;
}

.confirmAns {
  position: absolute;
  bottom: 40vh;
  z-index: 10;
  height: calc(8vh + 8vw);
  transform: translateX(100%);
  display: flex;
  flex-direction: column;
  width: calc(15vh + 25vw);
  font-size: calc(1vw + 0.8vh);
  font-weight: 600;
  text-transform: uppercase;
  background-color: #5ac0ef;
  color: black;

  .message_header {
    justify-self: flex-start;
    background-color: #203864;
    height: 20%;
    width: 100%;
    color: white;
    font-size: 0.5rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    h1 {
      color: white;
      margin-left: 1rem;
      font-size: calc(1vw + 0.8vh);
    }
  }

  .message_body {
    padding: 1rem;
    align-self: center;
    justify-self: center;
    height: 80%;
    width: 100%;

    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;

    .message__text {
      color: #203864;
      text-align: center;
    }

    .puzzle__btn-container {
      display: flex;
      width: 100%;
      justify-content: space-around;
      align-items: center;

      .message-btn {
        text-align: center;
        font-size: calc(0.8vh + 0.8vw);
        font-weight: 800;
        border-radius: calc(0.5vh + 0.5vw);
        width: calc(7vh + 7vw);
        padding: calc(0.3vh + 0.3vw) calc(0.4vh + 0.4vw);
        margin: calc(0.3vh + 0.3vw);
        color: #fff;
        cursor: pointer;

        background-color: #5ac0ef;
        border: 3px solid #203864;

        cursor: pointer;
        color: #203864;

        font-weight: 800;

        &:hover {
          background-color: #203864;
          color: white;
        }
      }

      .submit-message-btn {
        color: #203864;

        background-color: #5ac0ef;

        &:hover {
          background-color: #203864;
          color: white;
        }
      }
    }
  }

  // button {
  //     display: block;
  //     padding: 0px;
  //     border-radius: 50px;
  //     margin: .5rem;
  //     border: none;
  //     background-color: transparent;
  //     background-image: none;
  //     text-transform: uppercase;
  //     font-size: 1.4rem;
  //     cursor: pointer; }
  //     .message > button:hover {
  //       background-color: #00a5f1; }
  //     .message > button > svg {
  //       width: 20px; }
}
