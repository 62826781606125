.downloadWipe {
  position: relative;

  height: 100vh;
  width: 100vw;

  display: grid;
  place-content: center;

  .downloadWipe__incorrectPass {
    text-align: center;
    font-size: calc(1vh + 1.5vw);
    font-weight: 800;
    border-radius: calc(0.8vh + 0.8vw);
    border: none;
    min-width: calc(10.5vh + 10.5vw);
    max-width: calc(15.5vh + 15.5vw);
    padding: calc(0.4vh + 0.4vw) calc(0.6vh + 0.6vw);
    margin: calc(0.4vh + 0.4vw);
    position: absolute;
    color: white;
    background-color: red;
    left: 49%;
    top: 5%;
    transform: translate(-50%, -50%);
  }

  .downloadWipe__success {
    text-align: center;
    font-size: calc(1vh + 1.5vw);
    font-weight: 800;
    border-radius: calc(0.8vh + 0.8vw);
    border: none;
    min-width: calc(10.5vh + 10.5vw);
    max-width: calc(15.5vh + 15.5vw);
    padding: calc(0.4vh + 0.4vw) calc(0.6vh + 0.6vw);
    margin: calc(0.4vh + 0.4vw);
    position: absolute;
    color: white;
    background-color: green;
    left: 49%;
    top: 5%;
    transform: translate(-50%, -50%);
  }

  .downloadWipe__container {
    padding-top: calc(1.2vh + 1.2vw);

    height: 95vh;
    width: 98vw;

    border-radius: calc(1vh + 1vw);

    background-color: #ffe046bb;

    display: flex;
    align-items: center;
    flex-direction: column;

    h1 {
      font-size: calc(2.5vh + 2.5vw);
      font-weight: 800;
    }

    h3 {
      font-size: calc(1.5vh + 1.5vw);
      span {
        font-size: calc(1.8vh + 1.8vw);
        font-weight: 800;
      }
    }

    .downloadWipe__wipeBtn {
      text-align: center;
      font-size: calc(1vh + 1.5vw);
      font-weight: 800;
      border-radius: calc(0.8vh + 0.8vw);
      border: none;
      min-width: calc(10.5vh + 10.5vw);
      max-width: calc(15.5vh + 15.5vw);
      padding: calc(0.4vh + 0.4vw) calc(0.6vh + 0.6vw);
      margin: calc(0.4vh + 0.4vw);
      background-color: red;
      color: #fff;
      cursor: pointer;
      &:hover {
        color: #000000;
      }
    }

    form {
      margin-top: calc(1.5vh + 1.5vw);
      display: flex;
      flex-direction: column;
      align-items: center;

      label {
        font-size: calc(1vh + 1vw);
      }

      input {
        text-align: center;
        font-size: calc(1vh + 1.5vw);
        font-weight: 800;
        border-radius: calc(0.8vh + 0.8vw);
        border: none;
        padding: calc(0.4vh + 0.4vw) 0;
        margin: calc(0.4vh + 0.4vw);
        color: rgb(0, 0, 0);
        cursor: pointer;
      }
    }
  }
}
