@font-face {
  font-family: "Digital Numbers Regular";
  font-style: normal;
  font-weight: normal;
  src:
    local("Digital Numbers Regular"),
    url("../font/DigitalNumbers-Regular.woff") format("woff");
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body,
html {
  width: 100%;
}

.rp-radio-wrapper {
  display: flex;
  width: 100%;
  height: 1000px;
  width: 100vw;
  height: 100vh;
  font-family: "Digital Numbers Regular", Arial, Helvetica, sans-serif;
  font-size: 16px;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  /* background-color: #FFFFFF; */
}

.rp-radio {
  position: relative;
  width: 1020px;
  height: 680px;
  width: 70vw;
  height: 47vw;
  margin: auto;
  background-image: url("../images/radio.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (min-aspect-ratio: 2/1) {
  .rp-radio {
    transform: scale(0.75);
  }
}

.rp-controls {
  position: absolute;
  top: 38%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
  width: 60vw;
}

.rp-controls--left,
.rp-controls--right {
  display: flex;
  gap: 1vw;
}

.rp-btn {
  border: none;
  background-color: #ffffff;
  background-image: none;
}

.rp-controls__button {
  display: flex;
  width: 6vw;
  height: 3.5vw;
  border-radius: 0.8vw;
  background-color: #853a00;
  background-image: linear-gradient(to bottom, #c66300, #853a00);
  cursor: pointer;
}

.rp-controls__button:not(:last-child) {
  margin-bottom: 0.5vw;
}

.rp-controls__button:active {
  background-color: #c66300;
  background-image: linear-gradient(to bottom, #853a00, #c66300);
}

.rp-controls__button > svg {
  display: block;
  width: 3.2vw;
  margin: auto;
}

.rp-controls__frequency-display {
  display: flex;
  width: 12vw;
  border: 0.2vw solid #706a59;
  border-top: 0.5vw solid #706a59;
  background-color: #ffffff;
  box-shadow: inset rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.rp-controls__frequency-display--off {
  background-image: linear-gradient(to right, #bdbdbd, #ffffff);
}

.rp-controls__frequency-display--on {
  background: linear-gradient(
    90deg,
    rgba(241, 185, 30, 1) 0%,
    rgba(251, 232, 34, 1) 50%,
    rgba(241, 185, 30, 1) 100%
  );
}

.rp-frequency {
  font-family: "Aldrich", sans-serif;
  margin: auto;
  font-size: 5vw;
  margin-top: 2rem;
  color: #8d7005;
}

.rp-power-buttons {
  align-self: flex-end;
  display: flex;
  width: 8vw;
  height: 2.2vw;
  margin-bottom: 1vw;
  border-radius: 1vw;
  background-color: #c66300;
}

.rp-power-button {
  flex: 1;
  display: block;
  font-weight: 700;
  font-size: 1vw;
  text-transform: uppercase;
  cursor: pointer;
}

.rp-power-button--left {
  border-top-left-radius: 0.5vw;
  border-bottom-left-radius: 0.5vw;
}

.rp-power-button--off {
  background-color: #d13131;
  color: #ffffff;
  box-shadow: #d13131 1px 1px 12px;
}

.rp-power-button--default {
  color: #8b8b8b;
  background-color: #f1f1f1;
}

.rp-power-button--right {
  border-top-right-radius: 0.5vw;
  border-bottom-right-radius: 0.5vw;
}

.rp-power-button--on {
  background-color: #1fb61f;
  color: #ffffff;
  box-shadow: #1fb61f 1px 1px 12px;
}

.volControls {
  position: absolute;
  z-index: 11;
  bottom: calc(5vh + 5vw);
  right: calc(5vh + 5vw);
  /* transform: translateX(-50%); */
}

.slider__soundImg {
  height: calc(3vh + 3vw);
  cursor: pointer;
}

.slider__soundImg:active {
  transform: scale(0.9);
}

.slider {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 2vh;
  pointer-events: none;
}

/*Chrome*/
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type="range"] {
    overflow: hidden;
    -webkit-appearance: none;
    background-color: #e5dcbb;
  }

  input[type="range"]::-webkit-slider-runnable-track {
    height: 10px;
    -webkit-appearance: none;
    margin-top: -1px;
  }

  input[type="range"]::-webkit-slider-thumb {
    width: 10px;
    -webkit-appearance: none;
    height: 10px;
    cursor: ew-resize;
    background: #434343;
    box-shadow: -80px 0 0 80px #43e5f7;
  }
}
