* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  font-family: Arial, Helvetica, sans-serif !important;
  background-color: #ffffff !important;
}

button {
  display: block;
  font: inherit;
  border: none;
  cursor: pointer;
}

.geo__container {
  position: relative;
  color: #ffffff;
}

.geo__status-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 100;
}

.geo__status-wrapper {
  display: flex;
  gap: 2rem;
  padding: 1.2rem 2rem;
  margin-top: 4rem;
  background-color: #1aa1e6;
  font-weight: 600;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
}

.geo__status-title {
  color: #bff2f8;
  font-size: 0.7rem;
  text-transform: uppercase;
  margin-bottom: 0.3rem;
}

.geo__status-value {
  font-size: 1.5rem;
}

.geo__controlStatus-wrapper {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 100;
}

.geo__controlStatus-wrapper {
  display: flex;
  gap: 2rem;
  padding: 1.2rem 2rem;
  margin-top: 4rem;
  background-color: #1aa1e6;
  font-weight: 600;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  cursor: pointer;
}

.geo__controlStatus-wrapper:hover {
  background-color: #40baf7;
}

.geo__btn-cta {
  display: block;
  width: 100%;
  margin: 1rem 0;
  border-radius: 50px;
  padding: 0.6rem 0.4rem;
  font-size: 1rem;
  background-color: #6cb928;
  color: #ffffff;
}

.geo__result-wrapper {
  height: 40vh;
  background: linear-gradient(transparent, #000),
    linear-gradient(90deg, #28374c, #221d6c);
  padding: 2rem 0;
  text-align: center;
  color: #ffffff;
}

.geo__result-score {
  margin-bottom: 1rem;
  color: #fecd19;
  font-size: 1.6rem;
  font-weight: 600;
}

.geo__result-distance {
  margin-bottom: 1rem;
}

.geo__result-distance-val {
  padding: 0.25rem 0.75rem;
  text-transform: uppercase;
  background-color: hsla(0, 0%, 100%, 0.1);
  font-weight: 600;
}

.geo__btn--center {
  width: fit-content;
  margin: auto;
  padding-left: 2rem;
  padding-right: 2rem;
}

.geo__map-wrapper {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 5;
  display: flex;
  height: 100%;
  padding-top: 6.5rem;
  margin: 0 2rem;
  overflow: hidden;
  pointer-events: none;
}

.geo__map-wrapper > * {
  pointer-events: all;
}

.geo__map-container {
  position: relative;
  margin-top: auto;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  max-width: 100%;
  transition:
    opacity 0.1s ease,
    width 0.1s ease,
    height 0.1s ease;
}

.geo__map {
  border-radius: 0.25rem;
  flex: 1 1 auto;
  overflow: hidden;
  position: relative;
}

.geo__map-controls {
  display: flex;
  gap: 8px;
  width: fit-content;
  padding: 0.4rem;
  margin-left: auto;
  background: rgba(0, 0, 0, 0.5);
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.geo__map-control {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.25rem;
  height: 1.25rem;
  background-color: #ffffff;
  border-radius: 50px;
}

.geo__map-control > img {
  display: block;
  width: 70%;
  height: 70%;
}

.geo__map-control--increase-size > img {
  transform: rotate(-45deg);
}

.geo__map-control--decrease-size > img {
  transform: rotate(135deg);
}

@media only screen and (max-width: 600px) {
  .geo__status-wrapper {
    padding: 0.8rem 1.5rem;
  }

  .geo__status-title {
    font-size: 0.6rem;
  }

  .geo__status-value {
    font-size: 1.2rem;
  }
}

@media only screen and (max-width: 880px) {
  .geo__map-container {
    height: 100%;
    left: 0;
    position: relative;
    width: 81vw;
    bottom: 1rem;
    justify-content: flex-end;
  }
}

.geoguesser__timer {
  position: fixed;
  top: calc(0.5vh + 0.5vw);
  right: 40vw;
  color: #1aa1e6;
  text-align: center;
  width: 20vw;
  font-size: calc(1vw + 0.8vh);
  font-weight: 700;
  background-color: white;
  padding: calc(0.5vh + 0.5vw);
  border-radius: calc(0.7vh + 0.7vw);
}

.guesser__timerRed {
  color: red;
}

.confirmAns {
  z-index: 10;
}
