.wardengameStart {
  background-image: url("../../assets/gameAssets/bg.jpg");
  background-size: cover;
  background-position: center top;
  height: 100vh;
  width: 100vw;
  padding: 2rem;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 20%;

  .copyAndStart {
    display: flex;
  }

  .copyAndStartHeading {
    margin: calc(0.5vh + 0.5vw);
  }

  h1 {
    color: white;
    margin: calc(0.5vh + 0.5vw) 0;
    font-size: calc(1vh + 1.5vw);
  }

  button {
    color: white;
    font-size: calc(1vh + 1.5vw);
    font-weight: 800;
    border-radius: 2rem;
    border: none;
    width: 25rem;
    padding: 1rem;
    margin: 1rem;
    cursor: pointer;
    cursor: pointer;
    background-color: #1f3964;

    color: white;
    &:hover {
      background-color: #28497e;
    }
    &:active {
      background-color: #162a49;
    }
  }

  form {
    margin: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    input {
      text-align: center;
      font-size: calc(0.9vh + 0.9vw);
      font-weight: 800;
      border-radius: 2rem;
      border: none;
      width: calc(10vh + 10vw);
      padding: calc(0.4vh + 0.4vw) calc(0.6vh + 0.6vw);
      margin: calc(0.4vh + 0.4vw);
      background-color: #ffff;
      color: #203864;
      cursor: pointer;
    }
  }
}
