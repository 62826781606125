* {
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 500;
}

body {
  margin: 0;

  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body::after {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
  z-index: -1;
  content: url("./assets/gameAssets/ObservationPuzzle.png")
    url("./assets/room2/0_base.jpg") url("./assets/room2/1_cipherwheel.png")
    url("./assets/room2/2_leftcomputer.png") url("./assets/room2/3_lockpad.png")
    url("./assets/room2/4_painting.png") url("./assets/room2/5_radio.png")
    url("./assets/room2/6_renaissancepainting.png")
    url("./assets/room2/7_rightcomputer.png")
    url("./assets/room2Objects/paint1.jpg")
    url("./assets/room2Objects/paint2.jpg");
}
