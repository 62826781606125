.gameInfoButton {
  cursor: pointer;
  position: fixed;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  display: grid;
  place-content: center;
  font-size: calc(1.2vh + 1.2vw);
  font-weight: 800;
  top: calc(1.5vh + 1.5vw);
  right: calc(1.5vh + 1.5vw);
  z-index: 10;
  height: calc(2vh + 2vw);
  width: calc(2vh + 2vw);
  background-color: #49df23;
  padding: 0.5rem;
  border-radius: 100%;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.2);
  }
}

.gameInfo {
  position: fixed;
  top: calc(0.5vh + 0.5vw);
  right: calc(0.5vh + 0.5vw);

  z-index: 5;

  height: calc(8vw + 8vw);
  width: calc(9vw + 9vw);

  background-color: white;

  border-radius: calc(0.5vh + 0.5vw);

  padding: calc(4vh + 4vw) 0.5vw 0 0.5vw;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  h1 {
    font-size: calc(1.5vh + 1.5vw);
  }
  .code {
    color: #1aa1e6;
  }
}
