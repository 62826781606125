.overallResult {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row-reverse;
}
.overallResult__right {
  height: 100%;
  flex: 0.35;
  padding-top: 15%;

  display: flex;
  justify-content: center;
  align-items: flex-start;

  img {
    height: calc(25vh + 18vw);
  }
}

.overallResult__left {
  height: 100%;
  flex: 0.65;
  padding: 8% 0 0 10%;

  display: flex;
  align-items: center;
  flex-direction: column;

  h1 {
    color: #5ea9e7;
    margin-bottom: 2rem;
    font-size: calc(2vh + 2vw);
    margin-left: calc(2vh + 4vw);
  }

  .resultTable__Btn {
    text-align: center;
    font-size: calc(1vh + 1.5vw);
    font-weight: 800;
    border-radius: calc(0.8vh + 0.8vw);
    border: nThree;
    min-width: calc(11vh + 11.5vw);
    padding: calc(0.4vh + 0.4vw) calc(1vh + 1vw);
    margin: calc(0.4vh + 0.4vw);
    background-color: #1aa1e6;
    color: #fff;
    cursor: pointer;
    &:hover {
      background-color: #bff2f8;
      color: #1aa1e6;
    }
  }

  .resultTable {
    height: 60%;
    width: 90%;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .row {
      display: flex;
      width: 110%;
      height: 100%;
      padding: calc(0.1vh + 0.1vw);

      .col {
        display: flex;

        align-items: center;

        font-size: calc(1.5vh + 1vw);
        color: lightblue;
        font-weight: 500;
      }

      .col1 {
        width: 50%;
      }
      .col2 {
        width: 35%;
      }
      .col3 {
        width: 35%;
      }
    }

    .row1 {
      border-bottom: calc(0.2vh + 0.2vw) solid lightblue;
      height: 110%;
    }

    .row6Highlight {
      background-color: lightblue;

      .col {
        color: #203864;
        font-weight: 800;
      }
    }
  }
}
