.container {
  height: 100vh;
  width: 100vw;
  background-color: black;
}

.obsPuzzleMouse {
  height: 100vh;
  width: 100vw;
  background-image: url("../../assets/gameAssets/ObservationPuzzle.png");
  background-size: cover;
  object-fit: contain;
}

// .container__img{
//     // position: fixed;
//     top: 0;
//     right: 0;
//     bottom: 0;
//     left: 0;
//     height: 100vh;
//     width: 100vw;
//     overflow: hidden;
//     z-index: 1;
//     object-fit: fill;
// }
