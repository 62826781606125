.room2 {
  height: 100vh;
  width: 100vw;

  position: relative;

  background-size: cover;
  background-position: center;

  .hoverArea {
    position: absolute;
    height: calc(5vh + 5vw);
    width: calc(5vh + 5vw);

    cursor: pointer;
  }

  .cipherwheelArea {
    top: 44%;
    left: 72%;
  }

  .leftcompArea {
    top: 38%;
    left: 26%;
  }

  .lockpadArea {
    top: 30%;
    left: 16%;
  }

  .paintingArea {
    height: calc(10vh + 7vw);
    width: calc(10vh + 7vw);
    top: 25%;
    left: 42%;

    @media (min-width: 1400px) and (max-height: 800px) {
      top: 17%;
    }
  }

  .radioArea {
    top: 60%;
    left: 53%;
  }

  .painting2Area {
    height: calc(10vh + 7vw);
    width: calc(10vh + 7vw);
    top: 24%;
    right: 20%;
    @media (min-width: 1400px) and (max-height: 800px) {
      top: 15%;
    }
  }

  .rightCompArea {
    top: 41%;
    left: 85%;
  }
}

// body::after{
//    position:absolute; width:0; height:0; overflow:hidden; z-index:-1; // hide images
//    content:url("../../assets/room2/0_base.jpg") url("../../assets/room2/1_cipherwheel.png") url("../../assets/room2/2_leftcomputer.png") url("../../assets/room2/3_lockpad.png") url("../../assets/room2/4_painting.png") url("../../assets/room2/5_radio.png") url("../../assets/room2/6_renaissancepainting.png") url("../../assets/room2/7_rightcomputer.png")  url("../../assets/room2Objects/paint1.jpg") url("../../assets/room2Objects/paint2.jpg")// load images
// }

.base {
  background-image: url("../../assets/room2/0_base.jpg");
}
.cipherwheel {
  background-image: url("../../assets/room2/1_cipherwheel.png");
}
.leftcomp {
  background-image: url("../../assets/room2/2_leftcomputer.png");
}
.lockpad {
  background-image: url("../../assets/room2/3_lockpad.png");
}
.painting {
  background-image: url("../../assets/room2/4_painting.png");
}
.radioscreen {
  background-image: url("../../assets/room2/5_radio.png");
}
.painting2 {
  background-image: url("../../assets/room2/6_renaissancepainting.png");
}
.rightComp {
  background-image: url("../../assets/room2/7_rightcomputer.png");
}

.room2__imageContainer {
  height: 100vh;
  width: 100vw;

  display: grid;
  place-content: center;

  backdrop-filter: blur(5px);

  position: fixed;

  top: 0;
  left: 0;
  z-index: 10;

  .roomTwoPaintings {
    min-height: 80vh;
    min-width: 80vw;
    background-position: center;
    background-size: cover;
    border: 4px solid yellow;
    cursor: pointer;
    -webkit-box-shadow: 0 0 10px rgba(255, 238, 0, 0.74);
    box-shadow: 0 0 10px rgba(255, 238, 0, 0.74);
  }

  .roomTwoPaintings1 {
    background-image: url("../../assets/room2Objects/paint1.jpg");
  }

  .roomTwoPaintings2 {
    background-image: url("../../assets/room2Objects/paint2.jpg");
  }
}

.cipherWheel__container {
  height: 100vh;
  width: 100vw;

  display: grid;
  place-content: center;

  backdrop-filter: blur(15px);
  background-image: url("../../assets/gameAssets/woodbg.jpg");
  background-color: #30211a;

  position: fixed;

  top: 0;
  left: 0;
  z-index: 10;

  .cipherWheelCloseBtn {
    cursor: pointer;
    position: fixed;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    display: grid;
    place-content: center;
    font-size: 2.5rem;
    font-weight: 800;
    top: 5rem;
    right: 5rem;
    z-index: 11;
    height: 3.5rem;
    width: 3.5rem;
    background-color: #f7eedd;
    padding: 0.5rem;
    border-radius: 100%;
    transition: transform 0.2s ease;

    &:hover {
      transform: scale(1.2);
    }
  }
}

.Keypad__container {
  height: 100vh;
  width: 100vw;

  display: grid;
  place-content: center;

  backdrop-filter: blur(15px);
  background-image: url("../../assets/gameAssets/woodbg.jpg");
  background-color: #30211a;

  position: fixed;

  top: 0;
  left: 0;
  z-index: 10;

  .KeypadCloseBtn {
    cursor: pointer;
    position: fixed;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    display: grid;
    place-content: center;
    font-size: 2.5rem;
    font-weight: 800;
    top: 5rem;
    right: 5rem;
    z-index: 11;
    height: 3.5rem;
    width: 3.5rem;
    background-color: #f7eedd;
    padding: 0.5rem;
    border-radius: 100%;
    transition: transform 0.2s ease;

    &:hover {
      transform: scale(1.2);
    }
  }
}

.Radio__container {
  height: 100vh;
  width: 100vw;

  display: grid;
  place-content: center;

  backdrop-filter: blur(15px);
  background-image: url("../../assets/gameAssets/woodbg.jpg");

  position: fixed;

  top: 0;
  left: 0;
  z-index: 10;

  .RadioCloseBtn {
    cursor: pointer;
    position: fixed;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    display: grid;
    place-content: center;
    font-size: 2.5rem;
    font-weight: 800;
    top: 5rem;
    right: 5rem;
    z-index: 11;
    height: 3.5rem;
    width: 3.5rem;
    background-color: #f7eedd;
    padding: 0.5rem;
    border-radius: 100%;
    transition: transform 0.2s ease;

    &:hover {
      transform: scale(1.2);
    }
  }
}

.HackingPuzzleLeft__container {
  height: 100vh;
  width: 100vw;
  position: fixed;

  top: 0;
  left: 0;
  z-index: 10;

  // .back__btn{
  //   position: fixed;
  //   top:  calc(2vh + 2vw);
  //   left: calc(2vw + 2vh);
  //   font-size: calc(1.5vh + 1.5vw);
  //   cursor: pointer;
  //       color: lime;
  // }
}

.HackingPuzzleRight__container {
  height: 100vh;
  width: 100vw;
  position: fixed;

  top: 0;
  left: 0;
  z-index: 10;

  .back__btn {
    position: fixed;
    top: calc(2vh + 2vw);
    left: calc(2vw + 2vh);
    font-size: calc(1.5vh + 1.5vw);
    cursor: pointer;
    color: lime;
  }
}

.dontDisplay {
  display: none;
}

.room2SecretHintButton {
  cursor: pointer;
  position: fixed;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  display: grid;
  place-content: center;
  font-size: calc(1.2vh + 1.2vw);
  font-weight: 800;
  top: calc(1.5vh + 1.5vw);
  left: calc(1.5vh + 1.5vw);
  z-index: 10;
  height: calc(2vh + 2vw);
  width: calc(2vh + 2vw);
  background-color: #49df23;
  padding: 0.5rem;
  border-radius: 100%;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.2);
  }
}

.secretHintUsed {
  position: fixed;
  top: calc(0.5vh + 0.5vw);
  left: calc(0.5vh + 0.5vw);

  z-index: 5;

  height: calc(15vw + 15vw);
  width: calc(9vw + 9vw);

  background-color: white;

  border-radius: calc(0.5vh + 0.5vw);

  padding: calc(4vh + 4vw) 0.5vw 0 0.5vw;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  h1 {
    font-size: calc(1.2vh + 1.2vw);
    width: 100%;
    text-align: left;
    padding-left: 1vw;
  }

  .options {
    width: 100%;
    height: 100%;

    display: flex;
    // justify-content: space-around;
    align-items: space-around;
    justify-content: center;
    flex-direction: column;

    .option {
      font-size: calc(1.2vh + 1.2vw);
      .radioButon {
        height: calc(1vh + 1vw);
        width: calc(1vh + 1vw);
        margin: calc(0.5vh + 0.5vw);
      }
    }
  }
}
