.gameCreater {
  position: relative;

  background-image: url("../../assets/gameAssets/gameCreater.png");
  background-size: cover;
  background-position: center top;
  height: 100vh;
  width: 100vw;

  .downloadWipe__incorrectPass {
    text-align: center;
    font-size: calc(1vh + 1.5vw);
    font-weight: 800;
    border-radius: calc(0.8vh + 0.8vw);
    border: none;
    min-width: calc(10.5vh + 10.5vw);
    max-width: calc(15.5vh + 15.5vw);
    padding: calc(0.4vh + 0.4vw) calc(0.6vh + 0.6vw);
    margin: calc(0.4vh + 0.4vw);
    position: absolute;
    color: white;
    background-color: red;
    left: 49%;
    top: 5%;
    z-index: 10;
    transform: translate(-50%, -50%);
  }

  .overlay {
    position: absolute;
    height: 100%;
    width: 100%;

    top: 0;
    left: 0;

    background-color: #000000bd;
    z-index: 0;
  }

  .gameCreater__content {
    position: absolute;
    height: 100%;
    width: 100%;

    top: 0;
    left: 0;

    z-index: 10;
  }
}

.gameCreater__content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h1 {
    color: white;
    margin: calc(0.5vh + 0.5vw) 0;
    font-size: calc(1vh + 1.5vw);
  }

  form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    input {
      text-align: center;
      font-size: calc(0.9vh + 0.9vw);
      font-weight: 800;
      border-radius: 2rem;
      border: none;
      width: calc(14vh + 14vw);
      padding: calc(0.4vh + 0.4vw) calc(0.6vh + 0.6vw);
      margin: calc(0.4vh + 0.4vw);
      background-color: #ffff;
      color: #203864;
      cursor: pointer;
    }
  }

  .codetable {
    border-collapse: collapse;
    background-color: rgba(255, 255, 255, 0.897);
    min-width: calc(3vh + 3vw);
    thead {
      tr {
        font-size: calc(2vh + 2vw);
        font-weight: 800;
        border-bottom: 2px solid black;
      }
    }
    tbody {
      tr {
        font-size: calc(2vh + 2vw);
        text-align: center;
        padding: calc(1vh + 1vw);
        border-bottom: 2px solid black;
      }
    }
  }
}
