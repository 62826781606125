.toSheet {
  position: relative;

  background-image: url("../../assets/gameAssets/gameCreater.png");
  background-size: cover;
  background-position: center top;
  height: 100vh;
  width: 100vw;

  h1 {
    position: absolute;

    top: 30%;
    left: 50%;

    text-align: center;

    transform: translateX(-50%) translateY(-50%);
    z-index: 2;

    color: white;

    margin: calc(0.5vh + 0.5vw) 0;
    font-size: calc(1vh + 1.5vw);
  }

  .overlay {
    position: absolute;
    height: 100%;
    width: 100%;

    top: 0;
    left: 0;

    background-color: #000000bd;
    z-index: 0;
  }
  .toSheet_signin {
    position: absolute;

    top: 40%;
    left: 50%;

    transform: translateX(-50%) translateY(-50%);

    z-index: 2;
  }

  .toSheet_btn {
    position: absolute;

    top: 50%;
    left: 50%;

    transform: translateX(-50%) translateY(-50%);

    z-index: 2;
  }

  .toSheet_Google {
    position: absolute;

    display: flex;

    height: calc(2.5vh + 2.5vw);
    // width: calc(8vh + 8vw);

    top: 50%;
    left: 50%;

    transition: all 0.2s ease;

    transform: translateX(-50%) translateY(-50%);

    z-index: 2;

    background: #4285f4;

    &:hover {
      background: #2061ca;
    }

    img {
      align-self: left;

      height: 100%;
      // width: ;
    }

    .text {
      height: 100%;

      display: flex;
      align-items: center;

      font-size: calc(1vh + 1vw);
      color: white;
      padding: 0 calc(1vh + 1vw);
    }
  }
}
