.roomCodeExpired {
  height: 100vh;
  width: 100vw;

  background-image: url("../../assets/gameAssets/bg.jpg");
  background-position: center top;
  background-size: cover;

  display: grid;
  place-content: center;

  h1 {
    margin-top: calc(10vw + 5vh);
    color: white;
    text-align: center;
    font-size: calc(3vh + 3vw);

    span {
      font-family: "Barlow Condensed", sans-serif;
      color: red;
    }
  }

  .roomCodeExpired__btn {
    text-align: center;
    font-size: calc(1vh + 1.5vw);
    font-weight: 800;
    border-radius: calc(0.8vh + 0.8vw);
    border: none;
    min-width: calc(10.5vh + 10.5vw);
    max-width: calc(15.5vh + 15.5vw);
    padding: calc(0.4vh + 0.4vw) calc(0.6vh + 0.6vw);
    background-color: #1aa1e6;
    color: #fff;
    cursor: pointer;
    &:hover {
      background-color: #bff2f8;
      color: #1aa1e6;
    }
    margin: calc(1vh + 1vw) auto;
  }
}
