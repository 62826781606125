.loading {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  height: 100vh;
  width: 100vw;
  display: grid;
  place-content: center;
  background-color: black;

  h2 {
    color: #bef2f8;
    margin-top: 5rem;
    text-align: center;
  }
}
