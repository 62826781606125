.roomThreeResult {
  height: 100vh;
  width: 100vw;
  display: flex;

  background-image: url("../../assets/gameAssets/FarrowBaseImage.png");
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}
.roomThreeResult__left {
  height: 100%;
  flex: 0.3;
  padding-top: 8%;

  display: flex;
  justify-content: center;
  align-items: flex-start;

  img {
    height: calc(13vh + 7vw);
  }
}

.roomThreeResult__right {
  height: 100%;
  flex: 0.7;
  padding: 8% 0 4% 0;

  display: flex;
  align-items: center;
  flex-direction: column;

  h1 {
    color: #5ea9e7;
    margin-bottom: 2rem;
    font-size: calc(2vh + 2vw);
    margin-right: calc(2vh + 6vw);
  }

  .resultTable__Btn {
    text-align: center;
    font-size: calc(1vh + 1.5vw);
    font-weight: 800;
    border-radius: calc(0.8vh + 0.8vw);
    border: nThree;
    min-width: calc(11vh + 11.5vw);
    padding: calc(0.4vh + 0.4vw) calc(1vh + 1vw);
    margin: calc(0.4vh + 0.4vw);
    margin-right: calc(5vh + 5vw);
    background-color: #1aa1e6;
    color: #fff;
    cursor: pointer;
    &:hover {
      background-color: #bff2f8;
      color: #1aa1e6;
    }
  }

  .resultTable {
    height: 60%;
    width: 100%;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .row {
      display: flex;
      width: 90%;
      height: 100%;

      .col {
        display: flex;

        align-items: center;

        font-size: calc(1.5vh + 1vw);
        color: lightblue;
        font-weight: 500;
      }

      .col1 {
        width: 45%;
      }
      .col2 {
        width: 35%;
      }
      .col3 {
        width: 25%;
      }
    }

    .row6 {
      padding: calc(1vh + 1vw) 0;
      .col {
        font-weight: 800;
      }
    }
  }
}
