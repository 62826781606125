.cipherWheel {
  height: 80vh;
  width: 80vw;

  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cipherWheelContainer {
  transform: scale(1.4);
  display: flex;
  align-items: center;
  justify-content: center;
}

.outerCipherWheel {
  position: relative;
  background-image: url("../../assets/cipherwheel/outerWheel.png");
  height: 650px;
  width: 650px;
  background-size: cover;
  display: flex;
  justify-content: center;
}
.innerCipherWheel {
  position: absolute;
  top: 78px;
  left: 78px;
  height: 494px;
  transition: transform 0.4s ease-in-out;
  pointer-events: none;
}

.arrow {
  cursor: pointer;
  height: 7rem;
  position: absolute;
  top: 38%;
  transform: rotate(98deg);
}

.cipher__resetBtn {
  position: absolute;
  margin: 3rem 0 3rem 3.7rem;
  padding: 0.5rem 1rem;
  border: none;
  top: 50%;
  left: 36%;
  background-color: #4b4841;
  font-size: 1.2rem;
  border-radius: 1rem;
  font-weight: 500;
  cursor: pointer;
  color: #f7eedd;
  transition: background-color 0.5s ease;
}

.cipher__resetBtn:active {
  background-color: #808080;
}

.soundButton {
  position: absolute;
  bottom: 5rem;
  right: 5rem;
  background-color: #f7eedd;
  padding: 0.5rem;
  border-radius: 100%;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.soundButton img {
  height: 2.5rem;
}

.soundButton:hover {
  transform: scale(1.2);
}
