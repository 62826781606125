.hidden {
  display: none !important;
}

.hp__center {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  width: 100vw;
  height: 100vh;
}

.hp__center > span {
  margin: auto;
}

.hp__main-wrapper {
  width: 100vw;
  height: 100vh;
  background-color: #031b0e;
  background-size: 100% 100%;
  background-image: url("../images/greenscreen.png");
  overflow: hidden;
  font-family: monospace;
}

.hp__main-wrapper:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: calc(100% * 0.0945) calc(100% * 0.122);
  background: linear-gradient(#375 50%, #111 50%);
  background-size: 100% 6px;
  animation: pulse 5s linear infinite;
  mix-blend-mode: darken;
  pointer-events: none;
  z-index: 10000;
}

.hp__effects-wrapper {
  position: absolute;
  width: min(1024px, 90vw, 117vh);
  height: calc(min(1024px, 90vw, 117vh) / 1.3);
  top: calc(
    min(
        min(1024px, 90vw, 117vh) / 0.464 / 980 * 147.2,
        100vh - min(1024px, 90vw, 117vh) / 1.3
      ) / 2
  );
  left: calc((100vw - min(1024px, 90vw, 117vh)) / 2);
  border-radius: calc(100% * 0.0945) / calc(100% * 0.122);
}

.hp__effects2,
.hp__effects1 {
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
}

.hp__effects1 {
  overflow-x: hidden;
  overflow-y: hidden;
  position: absolute;
}

.hp__effects2 {
  padding: 7%;
  font-size: min(
    (min(1024px, 90vw, 117vh) - 14%) / 59 * 1.65,
    ((min(1024px, 90vw, 117vh) / 1.3) - 14%) / 24.5 * 0.83
  );
  letter-spacing: calc(
    (min(1024px, 90vw, 117vh) / 62 - 0.22%) -
      (
        min(
            min(1024px, 90vw, 117vh) / 0.0279 - 0.39%,
            min(1024px, 90vw, 117vh) / 0.049 - 0.528%
          ) / 35.75 / 62
      )
  );
  line-height: 1.1;
}

.hp__hacking {
  width: calc(min(1024px, 90vw, 117vh) - 14%);
  height: calc((min(1024px, 90vw, 117vh) / 1.3) - 14%);
}

.hp__hacking-symbols {
  display: inline-block;
}

.hp__click-to-start {
  width: 100%;
  height: 100%;
}

.hp__click-to-start p {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.hp__terminal-off .hp__effects1:before {
  animation: initial;
  opacity: 0.14;
}

.hp__active-command-prompt {
  display: inline;
}

.hp__loading {
  line-height: 0px;
}

.hp__loading > p {
  white-space: pre;
}

.hp__cp {
  line-height: normal;
  padding: 0 0 2% 0 !important;
}

.hp__cp:empty {
  display: none !important;
}

.txt,
.hp__splash-page,
#cp-cursor,
.hp__hack-cursor1,
.hp__loggedin-prompt {
  margin: 0;
  color: #4f5;
  text-shadow: 0em 0em 0.5em #23824c;
  font-family: monospace;
  font-weight: bold;
}

/* font fixes due to global font change */

.hp__hacking-symbols,
span {
  font-family: monospace;
}

.hp__active-command-prompt span {
  font-family: monospace;
}

.hp__feedback span {
  font-family: monospace;
}

.hp__click-to-restart div {
  font-family: monospace;
}

.hp__highlight,
.bracketpair,
.hp__click-to-start p .hp__click-to-restart div {
  color: #062414;
  text-shadow: 0em 0em 1em #23824c;
  background: #4f5;
}

.hp__rest {
  width: 100vw;
}

.hp__feedback {
  margin-left: 8px;
}

.hp__content {
  margin: 0;
  vertical-align: top;
  display: inline-block;
  white-space: nowrap;
}

.hp__attempts-blocks {
  letter-spacing: -0.1px;
}

.hp__results {
  height: 100%;
  display: inline-block;
}

.hp__entry {
  bottom: 0;
}

@keyframes flash {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-webkit-keyframes flash {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1.001);
    opacity: 0.14;
  }

  8% {
    transform: scale(1);
    opacity: 0.13;
  }

  15% {
    transform: scale(1.004);
    opacity: 0.14;
  }

  30% {
    transform: scale(1.002);
    opacity: 0.11;
  }

  100% {
    transform: scale(1);
    opacity: 0.14;
  }
}

@-webkit-keyframes pulse {
  0% {
    transform: scale(1.001);
    opacity: 0.14;
  }

  8% {
    transform: scale(1);
    opacity: 0.13;
  }

  15% {
    transform: scale(1.004);
    opacity: 0.14;
  }

  30% {
    transform: scale(1.002);
    opacity: 0.11;
  }

  100% {
    transform: scale(1);
    opacity: 0.14;
  }
}

#command-prompt,
#cp-cursor {
  display: inline;
}

.hp__cursor-off {
  display: none !important;
}

.hp__cursor-flash {
  animation: flash 0.5s step-start 0s infinite;
  -webkit-animation: flash 0.5s step-start 0s infinite;
}

.hp__blinker {
  animation: flash 1s step-start 0s infinite;
  -webkit-animation: flash 1s step-start 0s infinite;
}

.hp__splash-page {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.hp__divider {
  height: 0.5vh;
  background: #4f5;
  width: 80%;
  box-shadow: 0em 0em 0.5em #23824c;
  margin: 0 0 0 10%;
}

.hp__loading,
#command-prompt {
  height: 100%;
}

.hp__os-header {
  text-align: center;
  padding-bottom: 2%;
}

.hp__loggedin-prompt {
  position: absolute;
  top: calc(100% - 1.5 * 7%);
}

hr {
  display: none;
}

.portrait {
  display: none;
}

@media (orientation: portrait) {
  .portrait {
    display: block;
  }

  .hp__landscape {
    display: none;
  }

  body {
    background: #111;
  }
}

@-moz-document url-prefix() {
  img {
    width: 100%;
  }
}

.hackingPuzzleInstruction {
  position: absolute;
  top: 5%;
  right: 5%;
  background-color: green;
  border-radius: 100%;
  height: calc(1.5vh + 1.5vw);
  width: calc(1.5vh + 1.5vw);

  font-weight: 800;
  font-size: calc(1vh + 1vw);
  z-index: 7;

  display: grid;
  place-content: center;

  cursor: pointer;
}

.hackingPuzzleHintInstructionContent {
  padding: calc(1.5vh + 1.5vw) calc(1vh + 1vw);
  position: absolute;
  top: 3%;
  right: 3%;
  background-color: #1a351d;
  box-shadow: 7px 7px 10px rgba(0, 0, 0, 0.7);

  width: calc(20vh + 20vw);
  border-radius: calc(0.5vh + 0.5vw);
  z-index: 4;
}

.hackingPuzzleHintInstructionContent .heading {
  font-size: calc(1.2vh + 1.2vw);
}
.hackingPuzzleHintInstructionContent .para {
  font-size: calc(1vh + 1vw);
}

#controlUpdateHP {
  border: 2px solid #4f5;
  left: 11vw;
}

#controlUpdateHP h1 {
  color: #4f5;
}

.permanentControlInfo {
  position: absolute;
  top: 0vh;
  color: #4f5;
}

.takeControlBtn {
  position: absolute;
  right: 10%;
  top: 0%;
  padding: calc(0.5vh + 0.5vw) calc(1vh + 1vw);
  background-color: #23824c;
  color: #4f5;
}

.takeControlBtn:hover {
  background-color: #31ac66;
}

.back__btn {
  position: fixed;
  top: calc(2vh + 2vw);
  left: calc(2vw + 2vh);
  font-size: calc(1.5vh + 1.5vw);
  cursor: pointer;
  color: lime;
}
