body,
html {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: auto;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 16px;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  position: relative;
  z-index: -3;
  background-color: #020211;
}

.heading--2 {
  margin-bottom: 0.2vw;
  font-size: calc(0.8vw + 0.9vh);
  font-weight: 600;
  text-align: center;
  color: #58caff;
}

.heading--2--underline {
  border-bottom: 0.3vh solid #58caff;
  width: fit-content;
  padding: 0.2vh;
  margin: 0 auto 1vh auto;
  text-transform: uppercase;
}

.text {
  font-size: calc(0.72vw + 0.7vh);
  line-height: 1.3;
  color: #58caff;
  text-align: left;
  padding-bottom: 1vh;
}

.puzzle-page {
  display: flex;
  width: 100%;
}

.puzzle-container {
  width: 77%;
  position: absolute;
  top: 13%;
  left: 50%;
  transform: translateX(-50%);
  margin: auto;
}

#HUDvideo {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  z-index: -100;
  object-fit: fill;
}

#HUDvideoFull {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  z-index: -100;
  object-fit: fill;
}

.puzzle {
  display: grid;
  grid-template-columns: repeat(2, 43vw);
  grid-template-rows: 44vh 30vh;
  grid-template-areas: "situation puzzle" "situation answers";
  justify-content: center;
  gap: 0.5vw;
  justify-items: stretch;
  align-items: stretch;
  width: 100%;
}

.puzzle-2 {
  display: grid;
  grid-template-columns: repeat(2, 43vw);
  grid-template-rows: 50vh 26vh;
  grid-template-areas: "situation puzzle" "situation answers";
  justify-content: center;
  gap: 0.5vw;
  justify-items: stretch;
  align-items: stretch;
  width: 100%;
}

.grid-item--1,
.grid-item--2,
.grid-item--3,
.grid-item--4 {
  justify-self: stretch;
  align-self: stretch;
}

.grid-item--1 {
  grid-area: situation;
}

.grid-item--2 {
  height: 100%;
  grid-area: notes;
}

.grid-item--3 {
  grid-area: puzzle;
}

.grid-item--4 {
  grid-area: answers;
}

.subsection {
  height: 100%;
  padding: 0.7vw 1vw;
  background-color: #214480ab;
  border: thin solid #58caff;
}

.subsection-txt {
  overflow-y: auto;
}
.subsection-txt::-webkit-scrollbar {
  width: 10px;
}
.subsection-txt::-webkit-scrollbar-track {
  background: transparent;
}
.subsection-txt::-webkit-scrollbar-thumb {
  background: #58caff;
}
.subsection-txt::-webkit-scrollbar-thumb:hover {
  background: #00a5f1;
}

.subsection__content {
  padding-bottom: 3.4vh;
}

.subsection__content:last-child {
  padding-bottom: 0;
}

.btn {
  display: block;
  padding: 0.5vh 0.5vw;
  font-size: calc(8px + (14 - 8) * (100vw - 900px) / (3840 - 900));
  font-weight: 600;
  border-radius: 0;
  border: none;
  background-color: white;
  background-image: none;
  text-transform: uppercase;
  cursor: pointer;
}

.primary-btn {
  background-color: #58caff;
  color: black;
}

.primary-btn:hover {
  box-shadow: 0px 0px 10px #58caff;
}

.btn--center {
  display: flex;
  width: 100%;
  padding: 16px 0;
}

.btn--center > button {
  margin: auto;
}

.puzzle__btn-container {
  align-self: center;
  justify-self: center;
}

.puzzle__btn-container__puzzle_3 {
  align-self: center;
  justify-self: center;
  margin-left: calc(2vh + 2vw);
}

.puzzle__btn-container-2 {
  grid-area: 4/4;
  align-self: center;
  justify-self: center;
  margin: 0 4px;
}

.puzzle__btn {
  display: flex;
  gap: 4px;
}

.puzzle__btn .btn {
  width: calc(2.4vh + 2.4vw);
  margin: 2px;
}

.puzzle__btn--center {
  display: flex;
  width: 100%;
  margin: 4px auto;
}
.puzzle__btn--center > .btn {
  margin: auto;
  width: 40%;
}

.notes-input {
  width: 100%;
  height: 75%;
  padding: 8px;
  font-size: calc(0.62vw + 0.7vh);
  color: #58caff;
  text-transform: uppercase;
  font-family: inherit;
  resize: none;
  background-color: transparent;
  background-image: none;
  border: none;
  text-align: center;
  border: 1px solid black;
  outline: none;
}

.notes-input::placeholder {
  color: #0097dd;
}

.puzzle-grid {
  width: 100%;
  height: 48vh;
  display: grid;
  grid-template-columns: 7vw repeat(2, 8.96vw);
  grid-template-rows: 10vh repeat(2, 15vh);
  justify-content: center;
}

.puzzle__item--empty {
  border-bottom: 0.21vw solid #58caff;
  border-right: 0.21vw solid #58caff;
}

.puzzle__item--h {
  display: grid;
  grid-template-columns: repeat(5, 1.75vw);
  grid-template-rows: 100%;
  grid-template-areas: "data data data data data" "data data data data data" "data data data data data" "data data data data data" "data data data data data";
  border-top: 0.21vw solid #58caff;
  border-bottom: 0.21vw solid #58caff;
  border-right: 0.21vw solid #58caff;
  line-height: 0.9;
}

.puzzle__data--h {
  display: flex;
  padding: 0.5vh 0.5vw;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  transform: rotateZ(180deg);
  background-color: #355475cb;
  font-size: calc(0.4vw + 0.6vh);
  font-weight: 600;
  color: #58caff;
}

.puzzle__data--h:not(:last-child) {
  border-left: 1px solid #58caff;
}

.squares > div:nth-child(5n + 5) {
  border-right: none;
}

.puzzle__item--v {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: repeat(5, 20%);
  grid-template-areas: "data" "data" "data" "data" "data";
  border-left: 0.21vw solid #58caff;
  border-bottom: 0.21vw solid #58caff;
  border-right: 0.21vw solid #58caff;
}

.puzzle__data--v {
  padding: 0 0.5vh;
  display: flex;
  background-color: #355475cb;
  font-size: calc(0.4vw + 0.6vh);
  font-weight: 600;
  color: #58caff;
}

.puzzle__data--v > .center {
  align-self: center;
}

.puzzle__data--h > .center {
  align-self: center;
}

.puzzle__data--v:not(:last-child) {
  border-bottom: 0.105vw solid #58caff;
}

.puzzle__item {
  border-bottom: 0.21vw solid #58caff;
  border-right: 0.21vw solid #58caff;
}

.squares {
  display: grid;
  grid-template-columns: repeat(5, 1.75vw);
  grid-template-rows: repeat(5, 2.9vh);
}

.squares > div {
  position: relative;
  background-color: #0c2952d2;
  border-right: 0.105vw solid #58caff;
}

.squares > div:not(:nth-child(n + 21)) {
  border-bottom: 0.105vw solid #58caff;
}

.squares > div > * {
  pointer-events: none;
}

.squares > div > svg {
  width: 2.6vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.squares > div:hover {
  background-color: #004ccfab;
}

.puzzle-label--hover {
  background-color: #58caff;
  color: #000000;
}

.puzzle-grid--2 {
  width: 100%;
  height: 48vh;
  display: grid;
  grid-template-columns: 6vw repeat(3, 7.09vw);
  grid-template-rows: 11.2vh repeat(3, 12.1vh);
  justify-content: center;
}

.puzzle__item--empty-2 {
  border-bottom: 0.2vw solid #58caff;
  border-right: 0.2vw solid #58caff;
}

.puzzle__item--h-2 {
  display: grid;
  grid-template-columns: repeat(4, 1.71vw);
  grid-template-rows: 100%;
  grid-template-areas: "data data data data" "data data data data" "data data data data" "data data data data" "data data data data";
  border-top: 0.2vw solid #58caff;
  border-bottom: 0.2vw solid #58caff;
  border-right: 0.2vw solid #58caff;
}

.puzzle__data--h-2 {
  display: flex;
  padding: 0.5vh 0.5vw;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  transform: rotateZ(180deg);
  background-color: #355475cb;
  font-size: calc(0.4vw + 0.6vh);
  font-weight: 600;
  color: #58caff;
}

.puzzle__data--h-2 > .center {
  align-self: center;
}

.puzzle__data--h-2:not(:last-child) {
  border-left: 0.1vw solid #58caff;
}

.puzzle__item--v-2 {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: repeat(4, 25%);
  grid-template-areas: "data" "data" "data" "data" "data";
  border-left: 0.2vw solid #58caff;
  border-bottom: 0.2vw solid #58caff;
  border-right: 0.2vw solid #58caff;
}

.puzzle__data--v-2 {
  padding: 0 0.3vh;
  display: flex;
  background-color: #355475cb;
  font-size: calc(0.4vw + 0.6vh);
  font-weight: 600;
  color: #58caff;
}

.puzzle__data--v-2:not(:last-child) {
  border-bottom: 0.1vw solid #58caff;
}

.puzzle__data--v-2 > .center {
  align-self: center;
}

.puzzle__item-2 {
  border-bottom: 0.2vw solid #58caff;
  border-right: 0.21vw solid #58caff;
}

.squares-2 {
  display: grid;
  grid-template-columns: repeat(4, 1.75vw);
  grid-template-rows: repeat(4, 2.9vh);
}

.squares-2 > div {
  position: relative;
  background-color: #0c2952d2;
  border-right: 0.1vw solid #58caff;
}

.squares-2 > div:not(:nth-child(n + 13)) {
  border-bottom: 0.1vw solid #58caff;
}

.squares-2 > div > * {
  pointer-events: none;
}

.squares-2 > div > svg {
  width: 2.6vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.squares-2 > div:hover {
  background-color: #004ccfab;
}

.puzzle-label-2--hover {
  background-color: #58caff !important;
  color: #000000 !important;
}

.message {
  position: absolute;
  bottom: 33vh;
  z-index: 200;
  height: calc(8vh + 8vw);
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  width: calc(15vh + 25vw);
  font-size: calc(1vw + 0.8vh);
  font-weight: 600;
  text-transform: uppercase;
  background-color: #5ac0ef;
  color: black;
}

.message .message_header {
  justify-self: flex-start;
  background-color: #203864;
  height: 20%;
  width: 100%;
  color: white;
  font-size: 0.5rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.message .message_header h1 {
  color: white;
  margin-left: 1rem;
  font-size: calc(1vw + 0.8vh);
}

.message .message_body {
  padding: 1rem;
  align-self: center;
  justify-self: center;
  height: 80%;
  width: 100%;

  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
}

.message__text {
  color: #203864;
  text-align: center;
}

.message-btn {
  text-align: center;
  font-size: calc(0.8vh + 0.8vw);
  font-weight: 800;
  border-radius: calc(0.5vh + 0.5vw);
  width: calc(7vh + 7vw);
  padding: calc(0.3vh + 0.3vw) calc(0.4vh + 0.4vw);
  margin: calc(0.3vh + 0.3vw);
  color: #fff;
  cursor: pointer;

  background-color: #5ac0ef;
  border: 3px solid #203864;

  cursor: pointer;
  color: #203864;

  font-weight: 800;
}

.submit-message-btn {
  color: #203864;

  background-color: #5ac0ef;
}
.message-btn:hover {
  background-color: #203864;
  color: white;
}

.submit-message-btn:hover {
  background-color: #203864;
  color: white;
}

.message .puzzle__btn-container {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
}

.message > button {
  display: block;
  padding: 0px;
  border-radius: 50px;
  margin: 0.5rem;
  border: none;
  background-color: transparent;
  background-image: none;
  text-transform: uppercase;
  font-size: 2rem;
  cursor: pointer;
}
.message > button:hover {
  background-color: #00a5f1;
}
.message > button > svg {
  width: 20px;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.answers-table {
  display: flex;
  gap: 2vw;
  width: 80%;
  margin: auto;
  text-align: center;
  color: #58caff;
  font-size: calc(0.6vw + 0.6vh);
  font-weight: 600;
  text-transform: capitalize;
}

.answers-table__col {
  width: 33%;
}

.answers-table__cell {
  display: flex;
  height: 3.5vh;
  background-color: rgba(147, 242, 255, 0.075);
}

.answers-table__cell > .center {
  margin: auto;
}

.answers-table__cell:not(:last-child) {
  margin-bottom: 1vh;
}

.answers-table__cell-r {
  position: relative;
}

.answers-table__cell-r:before {
  display: block;
  height: 100%;
  min-width: 100px;
  content: "";
  background-image: url("../images/left-elem-1.svg");
  background-repeat: no-repeat;
  background-position: left;
  position: absolute;
  z-index: 100;
  left: -4px;
  top: 0;
}

.answers-table__cell-r:after {
  display: block;
  height: 100%;
  min-width: 100px;
  content: "";
  background-image: url("../images/right-elem-1.svg");
  background-repeat: no-repeat;
  background-position: right;
  position: absolute;
  z-index: 100;
  right: -4px;
  top: 0;
}

.answers-table__cell-d {
  position: relative;
  border-radius: 10px;
}

.answers-table__cell-d:before {
  display: block;
  height: 100%;
  min-width: 100px;
  content: "";
  background-image: url("../images/left-elem-2.svg");
  background-repeat: no-repeat;
  background-position: left;
  position: absolute;
  z-index: 100;
  left: -6px;
  top: 0;
}

.answers-table__cell-d:after {
  display: block;
  height: 100%;
  min-width: 100px;
  content: "";
  background-image: url("../images/right-elem-2.svg");
  background-repeat: no-repeat;
  background-position: right;
  position: absolute;
  z-index: 100;
  right: -6px;
  top: 0;
}

.list {
  padding: 0.5vw;
  font-size: calc(0.72vw + 0.7vh);
  color: #58caff;
  margin-left: 10px;
}

.list--padding-top {
  padding-top: 0px;
}

.list > li:not(:last-child) {
  margin-bottom: 0.4vw;
}

.list--margin-top {
  margin-top: 8px;
}

@media screen and (min-width: 2220px) {
  /* #HUDvideo {
    height: 70vh;
    width: 70vw;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); } */

  .puzzle-container {
    top: 13%;
    left: 11.6%;
    transform: scale(1);
  }

  .heading--2 {
    font-size: calc(0.8vw + 0.74vh) !important;
  }

  .text,
  .list {
    font-size: calc(0.8vw + 0.68vh) !important;
  }

  .btn,
  .answers-table,
  .puzzle__data--h,
  .puzzle__data--v {
    font-size: calc(0.5vw + 0.68vh) !important;
  }

  .subsection__content:not(:last-child) {
    padding-bottom: 16px;
  }
}
@media screen and (min-width: 2873px) {
  .heading--2 {
    font-size: calc(0.8vw + 0.74vh) !important;
  }

  .text,
  .list {
    font-size: calc(0.79vw + 0.68vh) !important;
  }

  .btn,
  .answers-table,
  .puzzle__data--h,
  .puzzle__data--v {
    font-size: calc(0.5vw + 0.68vh) !important;
  }

  .subsection__content:not(:last-child) {
    padding-bottom: 16px;
  }
}

@media screen and (min-width: 3840px) {
  .heading--2 {
    font-size: calc(0.8vw + 0.74vh) !important;
  }

  .text,
  .list {
    font-size: calc(0.79vw + 0.68vh) !important;
  }

  .btn,
  .answers-table,
  .puzzle__data--h,
  .puzzle__data--v {
    font-size: calc(0.5vw + 0.68vh) !important;
  }
}

/*# sourceMappingURL=style.css.map */

.gamewithoutCodeWarning {
  color: white;
}

.result__tableOverlay {
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  backdrop-filter: blur(10px);
}

.result__table {
  z-index: 5;
  background-color: #0000009a;
  padding: calc(1vh + 1vw) 0;
  position: absolute;
  width: 50%;
  top: calc(15vh + 10vw);
  left: 25%;
  border-radius: calc(1vh + 1vw);
  border: 5px solid #0097dd;
}

.result__table .result__tableScore,
.result__puzzlePoints {
  color: #0097dd;
  width: 100%;
  text-align: center;
  font-size: calc(1.2vh + 1.2vw);
  margin-bottom: 1rem;
}

.nextPuzzle {
  text-align: center;
  font-size: calc(0.8vh + 0.8vw);
  font-weight: 800;
  border-radius: calc(0.8vh + 0.8vw);
  border: none;
  width: calc(8vh + 8vw);
  padding: calc(0.4vh + 0.4vw) calc(0.6vh + 0.6vw);
  margin: calc(0.6vh + 0.6vw) auto 0 auto;
  background-color: #1aa1e6;
  color: #fff;
  cursor: pointer;
}

.nextPuzzle:hover {
  background-color: #bff2f8;
  color: #1aa1e6;
}

.nextPuzzle:hover {
  background-color: #bff2f8;
  color: #1aa1e6;
}

.result__table .answers-table .answers-table__col .answerCell__correct {
  background-color: rgba(0, 128, 0, 0.589);
}
.result__table .answers-table .answers-table__col .answerCell__wrong {
  background-color: rgba(255, 0, 0, 0.623);
}

.puzzle__timerGridOne,
.puzzle__timerGridTwo,
.puzzle__timerGridThree {
  position: fixed;
  top: calc(0.5vh + 0.5vw);
  right: calc(1vh + 1vw);
  color: #1aa1e6;
  font-size: calc(1vw + 0.8vh);
  font-weight: 700;
}

.puzzle__timerRed {
  color: red;
}

.hideComponent {
  display: none;
}

.controlUpdate {
  position: absolute;
  padding: calc(1vh + 1vw) calc(1.5vh + 1.5vw);
  background-color: rgba(0, 0, 0, 0.678);
  width: 22vw;
  left: 39vw;
  border: 2px solid #1aa1e6;
  z-index: 10;
  top: calc(1vh + 1vw);
}

.controlUpdate h1 {
  color: #1aa1e6;
  text-align: center;
  width: 100%;
  font-size: calc(1.2vh + 1.2vw);
  font-weight: 700;
}

.inControInfo {
  position: absolute;
  top: 9%;
  left: 7%;
}
.inControInfo h1 {
  color: #1aa1e6;
  font-size: calc(0.8vh + 0.8vw);
}
