.choice {
  position: fixed;
  bottom: calc(15vh + 5vw);
  left: 33%;
  display: flex;
  justify-content: space-around;

  width: 50%;

  h1 {
    border-radius: 2rem;
    text-align: center;
    font-size: 1.5rem;
    font-weight: 800;
    border: none;
    width: 25rem;
    padding: 1rem;
    margin: 1rem;
    background-color: #1aa1e6;
    color: #fff;
    z-index: 3;
    cursor: pointer;
    &:hover {
      background-color: #bff2f8;
      color: #1aa1e6;
    }
  }
}
