.gameIntro__startBtn {
  text-align: center;
  font-size: calc(1vh + 1.5vw);
  font-weight: 800;
  border-radius: calc(0.8vh + 0.8vw);
  border: none;
  width: calc(10.5vh + 10.5vw);
  padding: calc(0.4vh + 0.4vw) calc(0.6vh + 0.6vw);
  margin: calc(0.4vh + 0.4vw);
  background-color: #1aa1e6;
  color: #fff;
  cursor: pointer;

  position: fixed;
  bottom: calc(5vh + 5vw);
  right: calc(15vh + 25vw);
  z-index: 3;
  &:hover {
    background-color: #bff2f8;
    color: #1aa1e6;
  }
}
