.waitingRoomHeader {
  position: absolute;
  top: 0;
  left: 0;
  height: calc(6vh + 6vw);
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.822);

  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  h1 {
    font-size: calc(2vh + 2vw);

    color: #bff2f8;
    margin-left: calc(10vh + 10vw);
    text-align: left;

    span {
      color: white;
      font-family: "Barlow Condensed", sans-serif;
    }
  }
}

.waitingRoom {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-image: url("../../assets/waitingRoom/waitingRoom.png");
  background-size: 100% 100%;

  .waitingRoom__heading {
    position: absolute;
    width: 100vw;
    text-align: center;

    font-size: calc(1vh + 1vw);

    top: calc(16vh + 16vw);
    color: #b2e1e7;

    text-decoration: underline;
  }

  .playerNames {
    position: absolute;

    top: calc(18vh + 18vw);
    // right: calc(14vh + 14vw);
    right: 29vw;

    height: calc(9vh + 9vw);
    width: 42vw;

    // border:2px dotted hotpink;

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: calc(1.3vh + 1.3vw) calc(1.3vh + 1.3vw) calc(
        1.3vh + 1.3vw
      ) calc(1.3vh + 1.3vw) calc(1.3vh + 1.3vw);

    h1 {
      color: #b2e1e7;
      margin-left: calc(1.5vh + 1.5vw);
      text-align: left;
      font-size: calc(0.8vh + 0.8vw);
    }
  }

  .gameBeginBtn {
    position: absolute;
    bottom: calc(1.5vh + 1.5vw);
    right: calc(2vh + 2vw);

    text-align: center;
    font-size: calc(1vh + 1.5vw);
    font-weight: 800;
    border-radius: calc(0.8vh + 0.8vw);
    border: calc(0.2vh + 0.2vw) solid #203864;
    min-width: calc(10.5vh + 10.5vw);
    padding: calc(0.4vh + 0.4vw) calc(0.6vh + 0.6vw);
    margin: calc(0.4vh + 0.4vw);
    background-color: #1aa1e6;

    color: #fff;
    cursor: pointer;
    &:hover {
      background-color: #bff2f8;
      color: #1aa1e6;
    }
  }
}
