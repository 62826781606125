* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
/* 
html,
body {
  width: 100%;
  font-family: 'Oswald', Arial, Helvetica, sans-serif;
  font-size: 16px;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
} */

.keypad-puzzle {
  display: flex;
  width: 100%;
  height: 1000px;
  width: 100vw;
  height: 100vh;
  background-image: url("../../../../assets/gameAssets/woodbg.jpg");
  background-color: #30211a;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}

.keypad-wrap {
  margin: auto;
  filter: drop-shadow(12px 12px 10px rgba(8, 4, 4, 0.7));
}

.keypad {
  position: relative;
  max-width: 540px;
  min-width: 300px;
  background-image: url("../images/keypadBg.jpg");
  background-color: #453631;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: rgba(8, 4, 4, 0.7) 12px 12px 10px;
  clip-path: polygon(72% 0, 100% 18%, 100% 100%, 0 100%, 0 0);
}

.keypad::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  margin: 10px;
  border-radius: 50%;
  background-color: #b49d97;
}

.keypad::after {
  content: "";
  position: absolute;
  top: 0;
  right: 26%;
  width: 20px;
  height: 20px;
  margin: 10px;
  border-radius: 50%;
  background-color: #b49d97;
}

.keypad-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr minmax(80px, 120px);
  grid-template-rows: 80px 1fr;
  grid-template-areas:
    "screen options"
    "keys options";
  gap: 2em;
  padding: 2.5em;
}

.keypad-container::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 36px;
  height: 36px;
  margin: 10px;
  border-radius: 50%;
  background-color: #b49d97;
}

.keypad-container::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 36px;
  height: 36px;
  margin: 10px;
  border-radius: 50%;
  background-color: #b49d97;
}

.keypad__screen {
  grid-area: screen;
  width: 100%;
  height: 68px;
  color: #ffffff;
  background-color: #0e0f0a;
  overflow: hidden;
}

.keypad__input {
  padding: 0.35em 0.7em;
  font-size: 2.5rem;
  line-height: 1;
  letter-spacing: 4px;
  color: #afdffc;
  text-shadow:
    0 0 25px #0066c9,
    0 0 10px #0066c9,
    0 0 500px #050305;
}

.keypad__keys {
  grid-area: keys;
  justify-self: center;
}

.keypad__numbers {
  display: grid;
  grid-template-columns: repeat(3, 68px);
  grid-template-rows: repeat(4, 68px);
  gap: 1.8em;
  justify-items: center;
  align-items: center;
}

.keypad__key {
  display: flex;
  width: 100%;
  height: 100%;
  font-family: inherit;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1;
  color: #130d0d;
  background-color: #907973;
  border-width: 10px;
  border-style: inset;
  border-top-color: #b89790;
  border-left-color: #b3a09b;
  border-bottom-color: #362825;
  border-right-color: #312322;
  box-shadow: rgba(7, 3, 3, 0.6) 6px 0px 5px;
  cursor: pointer;
}

button:active {
  transform: scale(0.99);
  border-bottom-color: #b89790;
  border-right-color: #b3a09b;
  border-top-color: #362825;
  border-left-color: #312322;
}

.keypad__key > .number {
  margin: auto;
  pointer-events: none;
}

.keypad__key--last-key {
  grid-column-start: 2;
}

.keypad__options {
  display: flex;
  flex-direction: column;
  gap: 2em;
  grid-area: options;
  align-self: center;
  justify-self: center;
}

.keypad__option {
  padding: 0.5em;
  font-family: inherit;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1;
  color: #130d0d;
  background-color: #907973;
  border-width: 6px;
  border-style: inset;
  border-top-color: #b89790;
  border-left-color: #b3a09b;
  border-bottom-color: #362825;
  border-right-color: #312322;
  box-shadow: rgba(7, 3, 3, 0.6) 4px 0px 5px;
  cursor: pointer;
}

@media screen and (max-width: 480px) {
  .keypad {
    width: 100%;
  }

  .keypad__input {
    padding: 0.2em;
  }

  .keypad__numbers {
    grid-template-columns: repeat(3, 58px);
    grid-template-rows: repeat(4, 58px);
    gap: 1.5em;
  }

  .keypad-container {
    padding: 2em 1em;
  }

  .keypad::before {
    width: 16px;
    height: 16px;
    margin: 8px;
  }

  .keypad::after {
    width: 16px;
    height: 16px;
    margin: 8px;
  }
}

@media screen and (max-width: 349px) {
  .keypad__numbers {
    grid-template-columns: repeat(3, 54px);
    grid-template-rows: repeat(4, 54px);
  }

  .keypad-container {
    gap: 10px;
  }

  .keypad__key {
    border-width: 8px;
  }

  .keypad__option {
    padding: 0.2em;
  }
}
