* {
  text-decoration: none;
}

#defaultBtn {
  text-align: center;
  font-size: calc(1vh + 1.5vw);
  font-weight: 800;
  border-radius: calc(0.8vh + 0.8vw);
  border: none;
  min-width: calc(10.5vh + 10.5vw);
  max-width: calc(15.5vh + 15.5vw);
  padding: calc(0.4vh + 0.4vw) calc(0.6vh + 0.6vw);
  margin: calc(0.4vh + 0.4vw);
  background-color: #1aa1e6;
  color: #fff;
  cursor: pointer;
  &:hover {
    background-color: #bff2f8;
    color: #1aa1e6;
  }
}

#transitionVideo {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  z-index: -100;
  object-fit: fill;
}

.videoSound {
  position: absolute;
  z-index: 10;
  bottom: 10%;
  right: 5%;
  background-color: white;
  height: calc(3vh + 3vw);
  width: calc(3vh + 3vw);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: #60bbe9;
  }

  img {
    height: 70%;
  }
}
